export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const VButton = () => import('../../landing/components/ui/button/VButton.vue' /* webpackChunkName: "components/v-button", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VCircleButton = () => import('../../landing/components/ui/button/VCircleButton.vue' /* webpackChunkName: "components/v-circle-button", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VAccordion = () => import('../../landing/components/ui/accordion/VAccordion.vue' /* webpackChunkName: "components/v-accordion", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VAccordionItem = () => import('../../landing/components/ui/accordion/VAccordionItem.vue' /* webpackChunkName: "components/v-accordion-item", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VCheckbox = () => import('../../landing/components/ui/checkbox/VCheckbox.vue' /* webpackChunkName: "components/v-checkbox", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VIcon = () => import('../../landing/components/ui/icon/VIcon.vue' /* webpackChunkName: "components/v-icon", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VRub = () => import('../../landing/components/ui/icon/VRub.vue' /* webpackChunkName: "components/v-rub", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const InputHint = () => import('../../landing/components/ui/input/InputHint.vue' /* webpackChunkName: "components/input-hint", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VInput = () => import('../../landing/components/ui/input/VInput.vue' /* webpackChunkName: "components/v-input", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VLinearProgress = () => import('../../landing/components/ui/linear-progess/VLinearProgress.vue' /* webpackChunkName: "components/v-linear-progress", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VScrollBox = () => import('../../landing/components/ui/scrollbox/VScrollBox.vue' /* webpackChunkName: "components/v-scroll-box", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const MediaSlide = () => import('../../landing/components/ui/swiper/MediaSlide.vue' /* webpackChunkName: "components/media-slide", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSwiper = () => import('../../landing/components/ui/swiper/VSwiper.vue' /* webpackChunkName: "components/v-swiper", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSwiperPaginationNew = () => import('../../landing/components/ui/swiper/VSwiperPaginationNew.vue' /* webpackChunkName: "components/v-swiper-pagination-new", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTab = () => import('../../landing/components/ui/tabs/VTab.vue' /* webpackChunkName: "components/v-tab", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTabBar = () => import('../../landing/components/ui/tabs/VTabBar.vue' /* webpackChunkName: "components/v-tab-bar", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTag = () => import('../../landing/components/ui/tag/VTag.vue' /* webpackChunkName: "components/v-tag", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
